<template>
  <v-toolbar-items class="w-full">
    <v-btn text class="nav-button text-capitalize" @click="goBack">
      <v-icon left>mdi-arrow-left</v-icon>
      Back to List
    </v-btn>
    <v-menu offset-y v-if="me.yellowfinId">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" class="text-capitalize">
          Reports <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="me.is.superAdmin || me.is.transportationAdmin" @click="openReportDashboard">
          <v-list-item-title>Open Report Builder</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <template v-for="(report, index) in reports">
          <v-list-item v-if="report.show" :key="index" @click="$router.push(`${route}/report/${report.reportUUID}`)">
            <v-list-item-title>{{ report.name }}</v-list-item-title>
            <v-list-item-icon v-if="report.icon">
              <v-icon>{{ report.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { tripReports, assignmentReports } from '@/reports';
import { GET_YELLOWFIN_TOKEN } from '@/store/modules/User/actions';

export default {
  name: 'ReportsMenu',
  inject: ['eventHub'],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['me', 'yellowfinRedirectUrl', 'yellowfinToken']),
    route() {
      const s = this.$route.path.split('/');
      return '/' + s[1];
    },
    reports() {
      if (this.route == '/trip-requests') return tripReports;
      else if (this.route == '/assignments') return assignmentReports;
      return [];
    },
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapActions('user', [GET_YELLOWFIN_TOKEN]),
    async openReportDashboard() {
      const { token } = await this.getYellowfinToken();
      window
        .open(
          `${process.env.VUE_APP_YELLOWFIN_SERVER}/logon.i4?LoginWebserviceId=${token}&disablelogoff=true&entry=VIEWDASHBOARD&dashboardUUID=a1f25fa4-c6e1-497e-bab5-0f77c732dbbe&yftoolbar=TRUE`,
          '_blank'
        )
        .focus();
    },
    goBack() {
      this.$router.push(this.route);
    },
  },
  watch: {},
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
  align-items: center;
}
.focused-search {
  width: calc(100vw - 351px);
  position: absolute;
  z-index: 20;
  background-color: #f5f5f5;
  height: 100%;
  align-items: center;
}
.w-full {
  width: 100%;
}
</style>
