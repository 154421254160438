export const assignmentReports = [
  {
    name: 'Daily Vehicle Schedule',
    reportUUID: 'edbcc730-9de9-4a22-ae4e-016692737edf',
    show: true,
  },
  {
    name: 'Beginning & Ending Mileage',
    reportUUID: '3b3097b6-7deb-4786-a008-6baf4d08351c',
    show: true,
  },
  {
    name: 'Driver Monthly Report',
    reportUUID: 'a0affdcf-2240-4d8f-9040-423bd1cef255',
    show: true,
  },
  {
    name: 'Driver Hours by Month (Detail)',
    reportUUID: '43e2f3bf-41bd-4f39-a817-b6f2428cd30d',
    show: true,
  },
  {
    name: 'Field Trips by School & Destination',
    reportUUID: 'a103597e-7b47-456c-94ff-b0c56390b099',
    show: true,
  },
  {
    name: 'Yearly Mileage per Requesting Location',
    reportUUID: 'd5ff5386-fc03-414e-bf04-b70c42b595db',
    show: true,
  },
  {
    name: 'RT Miles/Total Cost',
    reportUUID: 'e8469471-4230-48ad-8b54-ccf1b67f1764',
    show: true,
  },
];
