<template>
  <v-container fluid>
    <v-toolbar  height="50" class="mx-6 mb-2 mt-2" elevation="4" rounded>
      <ReportMenu />
    </v-toolbar>
    <div id="reportDiv"></div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { uniq } from 'lodash';
import { tripReports, assignmentReports } from '@/reports';
import ReportMenu from './ReportMenu.vue';

export default {
  inject: ['eventHub'],
  components: { ReportMenu },
  props: {},
  data() {
    return {
      reportUUID: this.$route.params.uuid,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('app', ['currentSemester']),
    ...mapGetters('user', ['me']),
    ...mapGetters('location', ['locationsById']),
    locations() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.readOnly
      )
        return [];
      const locationIds = uniq(this.me.roles.map((e) => e.locationIds).flat()).filter((e) => e);
      return locationIds.map((e) => this.locationsById[e].name);
    },
    showFilters() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.readOnly ||
        this.me.is.overnightOOSOnly ||
        this.me.is.reportsOnly
      );
    },
    yellowfin() {
      return window.yellowfin;
    },
  },
  created() {
    this.showReport();
  },
  beforeDestroy() {
    this.clearReport();
  },
  methods: {
    showReport() {
      const report = [...tripReports, ...assignmentReports].find((e) => e.reportUUID == this.reportUUID);
      const filterValues = [
        {
          filterId: 'Semester Name',
          valueOne: this.currentSemester.name,
        },
      ];
      if (this.locations.length) filterValues.push({ filterId: 'School', valueList: this.locations });

      const loadReport = () => {
        const yellowfin = window.yellowfin;
        const element = document.getElementById('reportDiv');
        if (!yellowfin || !element) {
          setTimeout(() => loadReport(), 1000);
          return;
        }
        const reportObj = {
          reportUUID: this.reportUUID,
          filterValues,
          element,
          showShare: false,
          showInfo: false,
          showFilters: this.showFilters,
        };
        if (report.width) reportObj.width = report.width;
        yellowfin
          .loadReport(reportObj)
          .then((r) => {
            console.log(r);
          })
          .catch((e) => console.log('error loading report', e, reportObj));
      };
      loadReport();
    },
    clearReport() {
      const el = document.getElementById('reportDiv');
      if (el) {
        el.replaceChildren();
        el.style.width = null;
      }
    },
  },
  watch: {
    '$route.params.uuid': {
      handler: function (value) {
        this.clearReport();
        this.reportUUID = value;
        this.showReport();
      },
    },
  },
};
</script>

<style scoped>
#reportDiv {
  width: 94%;
  height: calc(100vh - 150px);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
</style>
