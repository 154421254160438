export const tripReports = [
  {
    name: '# Trips by Destination',
    reportUUID: 'c2cb745a-a29a-420c-8c3b-723d7d712938',
    width: 600,
    show: true,
  },
  // {
  //   name: '# Trips by Field Trip Type',
  //   onClick: () => this.showReport('tripsByFieldTripType'),
  //   show: true,
  // },
  {
    name: '# Trips by Grade',
    reportUUID: '29213a58-fdc1-4b03-bbfa-261e199256e0',
    width: 800,
    show: true,
  },
  {
    name: '# Trips by Requester',
    reportUUID: 'db3d6c4f-35bc-41bd-8a0c-3968f7b2d9be',
    width: 500,
    show: true,
  },
  {
    name: '# Trips by Requesting Location',
    reportUUID: '8adbe767-e264-4e2c-a434-fe7253ba089d',
    width: 600,
    show: true,
  },
  {
    name: '# Trips by Trip Type',
    reportUUID: '666e2477-9209-42ab-ae38-de6124239d3f',
    width: 500,
    show: true,
  },
  {
    name: '# Trips By Trip Event',
    reportUUID: '05f837e3-08cf-4b05-948b-de09639f8435',
    width: 500,
    show: true,
  },
  {
    name: '# Students by Requesting Location',
    reportUUID: '730da535-1fbd-42a9-b884-2c86c4657c7d',
    width: 600,
    show: true,
  },
  {
    name: '# Trips By School By Leave Date',
    reportUUID: 'b00a7b00-6d1d-4570-b76c-164f2e8e5aaa',
    show: true,
  },
  {
    name: 'Trips Requested Within # of Days of Trip',
    reportUUID: '446ebea4-f569-4b14-a71b-963c531bb7f1',
    width: 400,
    show: true,
  },
  {
    name: 'Basic Trip Report',
    reportUUID: 'ff0e467e-5ab9-49d8-8428-b8a626842cf5',
    show: true,
  },
  {
    name: 'District Board Report',
    reportUUID: '20e12bda-8cad-4ae7-82e4-79ad7ea18dfe',
    show: true,
  },
];
